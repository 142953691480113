<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#D5DCED;"
      d="M352,512H96c-15.149,0-27.429-12.28-27.429-27.429V27.429C68.572,12.28,80.852,0,96,0h256
	c15.149,0,27.429,12.28,27.429,27.429v457.143C379.429,499.72,367.149,512,352,512z"
    />
    <path
      style="fill:#C7CFE2;"
      d="M379.429,97.222c-11.529-3.727-23.802-5.793-36.571-5.793C277.214,91.429,224,144.642,224,210.286
	s53.215,118.857,118.857,118.857c12.769,0,25.042-2.066,36.571-5.793V97.222z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M333.714,73.143H114.286c-5.049,0-9.143,4.094-9.143,9.143v347.429c0,5.049,4.094,9.143,9.143,9.143
	h219.429c5.049,0,9.143-4.094,9.143-9.143V82.286C342.857,77.237,338.764,73.143,333.714,73.143z"
    />
    <path
      style="fill:#E4EAF8;"
      d="M306.286,365.714H141.714c-10.099,0-18.286-8.186-18.286-18.286V256
	c0-10.099,8.186-18.286,18.286-18.286h164.571c10.099,0,18.286,8.186,18.286,18.286v91.429
	C324.572,357.528,316.385,365.714,306.286,365.714z"
    />
    <path
      style="fill:#D5DCED;"
      d="M306.286,237.714h-78.977c11.024,46.582,49.389,82.466,97.263,89.879V256
	C324.572,245.901,316.384,237.714,306.286,237.714z"
    />
    <path
      style="fill:#5B5D6E;"
      d="M242.286,45.714h-36.571c-5.054,0-9.143-4.094-9.143-9.143s4.089-9.143,9.143-9.143h36.571
	c5.054,0,9.143,4.094,9.143,9.143S247.34,45.714,242.286,45.714z"
    />
    <path
      style="fill:#464655;"
      d="M169.232,45.714c-5.054,0-9.187-4.094-9.187-9.143s4.045-9.143,9.098-9.143h0.089
	c5.054,0,9.143,4.094,9.143,9.143S174.286,45.714,169.232,45.714z"
    />
    <path
      style="fill:#AFB9D2;"
      d="M237.714,493.714h-27.429c-10.098,0-18.286-8.187-18.286-18.286c0-10.098,8.187-18.286,18.286-18.286
	h27.429c10.098,0,18.286,8.187,18.286,18.286C256,485.527,247.813,493.714,237.714,493.714z"
    />
    <path
      style="fill:#7F8499;"
      d="M233.143,310.857h-82.286c-5.054,0-9.143-4.089-9.143-9.143s4.089-9.143,9.143-9.143h82.286
	c5.054,0,9.143,4.089,9.143,9.143S238.197,310.857,233.143,310.857z"
    />
    <path
      style="fill:#00C3FF;"
      d="M187.429,274.286h-36.571c-5.054,0-9.143-4.089-9.143-9.143s4.089-9.143,9.143-9.143h36.571
	c5.054,0,9.143,4.089,9.143,9.143S192.482,274.286,187.429,274.286z"
    />
    <path
      style="fill:#AFB9D2;"
      d="M233.143,347.429h-82.286c-5.054,0-9.143-4.089-9.143-9.143s4.089-9.143,9.143-9.143h82.286
	c5.054,0,9.143,4.089,9.143,9.143S238.197,347.429,233.143,347.429z"
    />
    <path
      style="fill:#00C3FF;"
      d="M169.143,416h-18.286c-7.571,0-13.714-6.143-13.714-13.714c0-7.571,6.143-13.714,13.714-13.714
	h18.286c7.571,0,13.714,6.143,13.714,13.714C182.857,409.857,176.714,416,169.143,416z"
    />
    <g>
      <path
        style="fill:#AFB9D2;"
        d="M297.143,416h-18.286c-7.571,0-13.714-6.143-13.714-13.714c0-7.571,6.143-13.714,13.714-13.714
		h18.286c7.571,0,13.714,6.143,13.714,13.714C310.857,409.857,304.714,416,297.143,416z"
      />
      <path
        style="fill:#AFB9D2;"
        d="M233.143,416h-18.286c-7.571,0-13.714-6.143-13.714-13.714c0-7.571,6.143-13.714,13.714-13.714
		h18.286c7.571,0,13.714,6.143,13.714,13.714C246.857,409.857,240.714,416,233.143,416z"
      />
    </g>
    <path
      style="fill:#00C3FF;"
      d="M325.648,111.143c-42.462,7.037-76.267,41.845-82.318,84.458
	c-5.454,38.415,10.897,73.289,38.376,94.4c-7.711,14.095-16.379,24.762-22.458,31.373c-2.386,2.595-0.048,6.734,3.408,6.035
	c12.195-2.464,32.313-8.216,50.051-21.163c13.345,4.19,27.879,5.694,42.985,3.808c43.624-5.446,79.534-39.87,86.425-83.289
	C453.014,158.115,394.37,99.754,325.648,111.143z"
    />
    <circle style="fill:#87DCFF;" cx="342.857" cy="210.286" r="82.286" />
    <g>
      <path
        style="fill:#FFFFFF;"
        d="M342.902,167.429c-5.054,0-9.187-4.094-9.187-9.143s4.045-9.143,9.098-9.143h0.089
		c5.054,0,9.143,4.094,9.143,9.143S347.956,167.429,342.902,167.429z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M352,246.857v-45.714c5.054,0,9.143-4.094,9.143-9.143c0-5.049-4.089-9.143-9.143-9.143h-18.286
		c-5.054,0-9.143,4.094-9.143,9.143c0,5.049,4.089,9.143,9.143,9.143v45.714c-5.054,0-9.143,4.094-9.143,9.143
		c0,5.049,4.089,9.143,9.143,9.143H352c5.054,0,9.143-4.094,9.143-9.143C361.143,250.951,357.054,246.857,352,246.857z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
