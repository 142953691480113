import { render, staticRenderFns } from "./ecommerce17.vue?vue&type=template&id=659e7603&"
import script from "./ecommerce17.vue?vue&type=script&lang=js&"
export * from "./ecommerce17.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports